import React from "react";
import { useParams } from "react-router-dom";
import BackButton from "../components/microComponents/BackButton";
import Vimeo from "@u-wave/react-vimeo";

const Video = () => {
  const { videoId } = useParams();
  // const isLogedIn = useUserStore((state) => state.isLogedIn);
  // const user = useUserStore((state) => state.user);

  // console.log("videoId", videoId);
  return (
    <div className="Container pb-4">
      <div className="home_container">
        <BackButton title={"Video"} isSticky={true} />

        {videoId && (
          <div className="video">
            <Vimeo video={videoId} style={{ width: "100%" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
