import Client, { ClientOption } from "../client";

export default {
  // returns list of all the Course route
  getAllcourse: (options?: ClientOption) =>
    Client("GET", `course`, undefined, options),

  getCourseDetails: (id: number, options?: ClientOption) =>
    Client("GET", `course/${id}`, undefined, options),

  getChapters: (id: number, options?: ClientOption) =>
    Client("GET", `chapter/${id}`, undefined, options),

  getSections: (id: number, pageNo: number, options?: ClientOption) =>
    Client("GET", `section/${id}?page=${pageNo}`, undefined, options),

  getSectionDetails: (sectionId: number, options?: ClientOption) =>
    Client("GET", `get-section/${sectionId}`, undefined, options),

  getQuizzes: (groupId: number, options?: ClientOption) =>
    Client("GET", `quizzes/${groupId}`, undefined, options),

  getChaptersQuizzes: (params: any, options?: ClientOption) =>
    Client("POST", `chapter-quiz`, params, options),

  getFinalQuizzes: (options?: ClientOption) =>
    Client("GET", `final-quiz`, undefined, options),

  getErrorQuizzes: (options?: ClientOption) =>
    Client("GET", `get-error-quiz`, undefined, options),

  saveExamResult: (params: any, options?: ClientOption) =>
    Client("POST", `store-quiz-stats`, params, options),

  getStatictics: (options?: ClientOption) =>
    Client("GET", `get-quiz-stats`, undefined, options),

  resetStatictics: (options?: ClientOption) =>
    Client("POST", `reset-quiz-stats`, {}, options),

  resetStaticByChapterId: (id: number, options?: ClientOption) =>
    Client("POST", `reset-quiz-stats/${id}`, {}, options),

  validateRequestCourse: (params: any, options?: ClientOption) =>
    Client("POST", "validate-course-request", params, options),

  requestCourse: (params: any, options?: ClientOption) =>
    Client("POST", "request-course", params, options),

  getTranlation: (word: string, options?: ClientOption) =>
    Client("GET", `dictionary/${word}`, undefined, options),

  getDictionaryList: (pageNo: number, options?: ClientOption) =>
    Client("GET", `dictionary?page=${pageNo}`, undefined, options),

  getDictionary: (word: string, options?: ClientOption) =>
    Client("GET", `dictionary/${word}`, undefined, options),

  getDictionaryByWord: (keyword: number, options?: ClientOption) =>
    Client("GET", `dictionary?keyword=${keyword}`, undefined, options),

  getImportantWordList: (pageNo: number, options?: ClientOption) =>
    Client("GET", `important-word?page=${pageNo}`, undefined, options),

  getImportantNotesByChapter: (
    chapterId: number,
    pageNo: number,
    options?: ClientOption
  ) =>
    Client(
      "GET",
      `important-note/${chapterId}?page=${pageNo}`,
      undefined,
      options
    ),

  getImportantNotesByWord: (
    chapterId: number,
    keyword: number,
    options?: ClientOption
  ) =>
    Client(
      "GET",
      `search-important-note?keyword=${keyword}`,
      undefined,
      options
    ),

  getImportantWord: (word: string, options?: ClientOption) =>
    Client("GET", `important-word/${word}`, undefined, options),

  getChapterVideos: (chapterId: number, options?: ClientOption) =>
    Client("GET", `video/${chapterId}`, undefined, options),
};
