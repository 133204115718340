import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import p1 from "../assets/patante/p1.png";
import p2 from "../assets/patante/p2.png";
import p3 from "../assets/patante/p3.png";
import p4 from "../assets/patante/p4.png";
import p5 from "../assets/patante/p5.png";
import p6 from "../assets/patante/p6.png";
import p7 from "../assets/patante/p7.png";
import google from "../assets/googlePlay.webp";
import appStore from "../assets/AppStore.webp";
import useUserStore from "../stores/useUserStore";
import CourseApi from "../api/services/CourseApi";
import UserApi from "../api/services/UserApi";
import Utils from "../utils/Utils";
import useCourseStore from "../stores/useCourseStore";
import { errorToast } from "../utils/Toasts";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Home = () => {
  const navigate = useNavigate();
  const isLogedIn = useUserStore((state) => state.isLogedIn);
  const user = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const patenteLessons = useUserStore((state) => state.patenteLessons);
  const setPatenteLessons = useUserStore((state) => state.setPatenteLessons);
  const setOriginalQuizList = useCourseStore(
    (state) => state.setOriginalQuizList
  );
  const setQuizList = useCourseStore((state) => state.setQuizList);

  useEffect(() => {
    scrollToTop();
    fetchApiData();
  }, []);

  const fetchApiData = () => {
    setIsLoading(true);
    CourseApi.getAllcourse({
      onSuccess: (respose) => {
        setPatenteLessons(respose.data[0]);
        setIsLoading(false);
      },
      onError: () => setIsLoading(false),
    });

    if (isLogedIn) {
      UserApi.getProfile({
        onSuccess: (response) => {
          Utils.saveUserCreadential(response.data, window.accessToken, true);
        },
      });
    }
  };

  const fetchErrorQuizzes = () => {
    CourseApi.getErrorQuizzes({
      // showIndicator: true,
      onSuccess: (response) => {
        const formatedQuize = response.data.reduce(
          (result: any[], item: any) => [
            ...result,
            { ...item, answer: item.answer === "1" ? true : false },
          ],
          []
        );
        setOriginalQuizList(formatedQuize);
        setQuizList(JSON.parse(JSON.stringify(formatedQuize)));

        if (formatedQuize?.length > 0) {
          navigate(`/quizExam/CUSTOM`);
        } else {
          return errorToast("There is no error quizzes found.");
        }
      },
    });
  };

  const onNavigate = (successCall: () => void) => {
    if (!isLogedIn) {
      return navigate("/login");
    }

    if (patenteLessons?.user_has_course) {
      successCall();
    } else {
      // console.log(patenteLessons?.user_has_course);
      onRequestPressed();
    }
  };

  const onRequestPressed = () => {
    confirmAlert({
      title: "Request course?",
      message:
        "Unfortunately, you do not have permission to view the entire course. There is a €_PRICE_ fee for accessing the full course. Would you like to request this course in order to gain full access?".replace(
          "_PRICE_",
          patenteLessons.price
        ),
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            CourseApi.requestCourse(
              { user_id: user.id, course_id: patenteLessons.id },
              {
                onSuccess: (repponse) => {
                  console.log("requestCourse", repponse);
                },
              }
            );
          },
          className: "button-yes",
        },
        {
          label: "No",
          onClick: () => console.log("Action canceled!"),
          className: "button-no",
        },
      ],
    });
  };

  // console.log("user", user);
  return (
    <div className="Container pb-4">
      <div className="download_app">
        <span>To get better experience Please download our LM Patente app</span>
        <div className="n_download">
          <Link
            to="https://play.google.com/store/apps/details?id=com.lmpatente"
            target="_blank"
          >
            <img src={google} alt="" />
          </Link>
          <Link to="#" target="_blank">
            <img src={appStore} alt="" />
          </Link>
        </div>
      </div>

      <div className="home_container">
        <div className="title">আসুন হেসে খেলে বাংলায় পাতেন্তে শিখি</div>
        <div className="services">
          <div
            onClick={() => navigate(`/chapters/${patenteLessons?.id}`)}
            className={`service`}
          >
            <img src={p1} alt="service_image" />
            <span>Manuale con quiz</span>
          </div>

          <div
            onClick={() =>
              onNavigate(() => {
                navigate(`/ChaptersSelection/${patenteLessons?.id}`);
              })
            }
            className={`service`}
          >
            <img src={p2} alt="service_image" />
            <span>Quiz per argomento</span>
          </div>

          <div
            onClick={() =>
              onNavigate(() => {
                navigate(`/quizExam/SIMULATION`);
              })
            }
            className={`service`}
          >
            <img src={p4} alt="service_image" />
            <span>Simulazione esame</span>
          </div>

          <div
            onClick={() => onNavigate(fetchErrorQuizzes)}
            className={`service`}
          >
            <img src={p3} alt="service_image" />
            <span>Ripasso errori</span>
          </div>

          <div
            onClick={() =>
              onNavigate(() => {
                navigate(`/statistiche`);
              })
            }
            className={`service`}
          >
            <img src={p5} alt="service_image" />
            <span>পরীক্ষার ফলাফল</span>
          </div>

          <div
            className={`service`}
            onClick={() =>
              onNavigate(() => {
                navigate(`/chapters/${patenteLessons?.id}?type=video`);
              })
            }
          >
            <img src={p6} alt="service_image" />
            <span>ভিডিও থিউরি + কুইজ</span>
          </div>

          <div
            className={`service`}
            onClick={() =>
              onNavigate(() => {
                navigate(`/chapters/${patenteLessons?.id}?type=patente-boi`);
              })
            }
          >
            <img src={p7} alt="service_image" />
            <span>সহজ পাতেন্তে বই</span>
          </div>

          <div
            className={`service last`}
            onClick={() =>
              onNavigate(() => {
                navigate(`/dictionary`);
              })
            }
          >
            <img src={p7} alt="service_image" />
            <span>শব্দার্থ শিখুন</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
