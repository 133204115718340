import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import p9 from "../assets/patante/p9.png";
import p10 from "../assets/patante/p10.png";
import useUserStore from "../stores/useUserStore";
import CourseApi from "../api/services/CourseApi";

import BackButton from "../components/microComponents/BackButton";
import Skeleton from "react-loading-skeleton";

const VideoType = () => {
  const { chapterId } = useParams<{ chapterId: any }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLogedIn = useUserStore((state) => state.isLogedIn);
  const user = useUserStore((state) => state.user);
  const [videos, setVideos] = useState<any[]>([]);

  useEffect(() => {
    getChapters();
  }, []);

  const getChapters = () => {
    setIsLoading(true);
    CourseApi.getChapterVideos(chapterId, {
      onSuccess: (response) => {
        // console.log("response", response);
        setIsLoading(false);
        setVideos(response.data);
      },
      onError: (error) => {
        // console.log('getChapters error', error);
        setIsLoading(false);
      },
    });
  };

  const skeleton = (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <div className={`service`} key={index}>
          <div className="ManualConQuiz_item">
            <Skeleton height={80} width={80} />

            <div className="info">
              <div className="image">
                {/* <Skeleton circle={true} height={16} width={16} /> */}
              </div>
              <span className="item_name" style={{ width: "100%" }}>
                <Skeleton />
                {/* <Skeleton width={150} /> */}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  // console.log("videos", videos);
  return (
    <div className="Container pb-4" style={{ minHeight: "50vh" }}>
      <div className="home_container">
        <BackButton title={"Chapters"} isSticky={true} />

        <div className="services">
          {isLoading ? (
            skeleton
          ) : (
            <>
              {videos.map((item) => (
                <div
                  onClick={() => navigate(`/video/${item.url}`)}
                  key={item.id}
                  className={`service`}
                >
                  <img
                    src={item.type === "Theory" ? p10 : p9}
                    alt="service_image"
                  />
                  <span>{item.title}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoType;
